<template>
  <div class="refusedEntry">
    <el-row>
      <el-button @click="exportInternListClick">导出Intern List</el-button>
      <el-button @click="downloadAttachClick">打包下载实习生附件</el-button>
    </el-row>
    <el-row style="margin-top: 12px">
      <PocTable
        v-loading="loading"
        :data="tableData"
        :max-height="1000000000"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        @size-change="pageSizeChange"
        @selection-change="handleSelectionChange"
        @page-current-change="pageChange"
        stripe
      >
        <el-table-column type="selection" width="45"> </el-table-column>

        <el-table-column
          v-for="item in columnConfig1"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        >
        </el-table-column>
        <el-table-column key="hfmCode" label="HFM Code" width="150">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="点击查看详情"
              placement="top-start"
              :open-delay="800"
            >
              <div class="hfmCodeHover" @click="clickHfmCode(scope.row)">
                {{ scope.row.hfmCode }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          v-for="item in columnConfig2"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        >
        </el-table-column>
        <el-table-column
          v-for="item in columnConfig"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        ></el-table-column>

        <el-table-column label="Status" width="85" align="center">
          <template slot-scope="scope">
            <statusDisplay :statusContent="scope.row.status" />
          </template>
        </el-table-column>

        <el-table-column label="操作" width="50" fixed="right" align="center">
          <template slot-scope="scope">
            <RoleButton
              :role-status="scope.row.status"
              @onClickButton="onClickButton($event, scope.row)"
            />
          </template>
        </el-table-column>
      </PocTable>
    </el-row>
    <NewInternDialog ref="NewInternDialogRef" @onClose="onClose" />
  </div>
</template>

<script>
import searchFormLine from '@/views/intern/components/searchFormLine'
import { onTheJobColumn } from '@/views/intern/columnConfig/onTheJobColumn'
import statusDisplay from '@/views/intern/components/statusDisplay'
import RoleButton from '@/views/intern/components/RoleButton'
import NewInternDialog from '@/views/intern/dialog/NewInternDialog'
import {
  downloadAttach,
  exportInternList,
  internList,
  recall
} from '@/api/intern'
import {
  forRecruitmentColumn,
  forRecruitmentColumn1
} from '@/views/intern/columnConfig/forRecruitmentColumn'
import { getItem } from '@/utils/storage'
export default {
  name: 'refusedEntry',
  mixins: [$PCommon.TableMixin],
  components: {
    searchFormLine,
    statusDisplay,
    RoleButton,
    NewInternDialog
  },
  data() {
    return {
      columnConfig: onTheJobColumn,
      tableData: [],
      loading: false,
      maxHeight: '',
      selectVal: [],
      columnConfig1: forRecruitmentColumn,
      columnConfig2: forRecruitmentColumn1,
      searchVal: {}
    }
  },
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 380
    this.queryApi()
  },
  methods: {
    clickHfmCode(item) {
      console.log(item)
      this.$router.push({
        path: '/intern/internDetails',
        query: {
          id: item.id
        }
      })
    },
    onClose() {
      this.queryApi()
    },
    exportInternListClick() {
      let ruleForm = this.searchVal
      let data = {
        englishName: '',
        status: '',
        localName: '',
        ids: this.mapSelectValToIds(),
        companyName: '',
        lmName: '',
        title: '',
        taOwner: '',
        internalBrand: '',
        joinDate: [],
        leaveDate: [],
        tabType: '5',
        ...ruleForm
      }
      exportInternList(data)
    },
    mapSelectValToIds() {
      return this.selectVal.map((item) => item.id).toString(',')
    },
    isSelect() {
      if (this.selectVal.length) {
        return true
      } else {
        this.$message.warning('请选择')
        return false
      }
    },
    downloadAttachClick() {
      if (this.isSelect()) {
        const data = {
          ids: this.mapSelectValToIds()
        }
        downloadAttach(data)
      }
    },
    queryApi(val = {}) {
      if (val) {
        this.searchVal = val
      }

      const data = {
        englishName: '',
        status: '',
        localName: '',
        companyName: '',
        lmName: '',
        title: '',
        taOwner: '',
        internalBrand: '',
        joinDate: [],
        leaveDate: [],
        tabType: '5',
        tbhStatus: this.tbhStatus,
        pageSize: this.pageConfig.pageSize,
        pageNum: this.pageConfig.pageNum,
        ...val,
        ...getItem('internSearchData')
      }
      internList(data).then((res) => {
        if (res && res.code === 200) {
          if (res.data && res.data.list) {
            this.tableData = res.data.list
            this.pageConfig.total = res.data.total
          } else {
            this.tableData = []
            this.pageConfig.total = 0
          }
        }
      })
    },
    toWithdraw(row) {
      recall({ ids: row.id }).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('撤回成功')
          this.queryApi()
        }
      })
    },
    copy(row) {
      this.$refs.NewInternDialogRef.show({
        id: row.id,
        status: 'copy'
      })
      // this.$router.push({
      //   path: '/intern/newIntern',
      //   query: {
      //     id: row.id,
      //     status: 'copy'
      //   }
      // })
    },
    onClickButton(event, row) {
      const buttonObj = {
        copy: () => {
          this.copy(row)
        },
        toWithdraw: () => {
          this.toWithdraw(row)
        }
      }
      buttonObj[event]()
    },
    handleSelectionChange(val) {
      this.selectVal = val
    }
  }
}
</script>

<style lang="scss" scoped>
.refusedEntry {
}
</style>

<template>
  <div class="haveLeft">
    <div class="flexBet">
      <div></div>
      <div>
        <el-button @click="exportInternListClick" role="downloadResign" v-permission
                   v-loading.fullscreen.lock="fullscreenLoading">下载列表数据</el-button>
      </div>
    </div>
    <el-row style="margin-top: 12px">
      <PocTable
        v-loading="loading"
        :data="tableData"
        :max-height="10000000000000"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        @size-change="pageSizeChange"
        @selection-change="handleSelectionChange"
        @page-current-change="pageChange"
        stripe
      >
        <el-table-column type="selection" width="45"> </el-table-column>
        <el-table-column
          v-for="item in columnConfig"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        >
        </el-table-column>
        <el-table-column key="hfmCode" label="HFM Code" width="150">
          <template slot-scope="scope">
            {{ scope.row.hfmCode }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="item in columnConfig1"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
          align="center"
        >
        </el-table-column>

        <!-- <el-table-column
          v-for="item in columnConfigOps"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
          align="center"
        >
          <template v-slot="scope">
            <div v-if="['inOps','sendTa'].includes(item.key)">
              <el-tooltip
                v-if="scope.row[item.key] ==='是'"
                popper-class="workorder-reason-popper"
                effect="dark"
                :content="item.key === 'sendTa' ? scope.row.taOwner : scope.row.inOpsUserNames"
                placement="top">
                <span>{{ scope.row[item.key] }}</span>
              </el-tooltip>
              <span v-else></span>
            </div>
            <span v-else>{{scope.row[item.key]}}</span>
          </template>
        </el-table-column> -->

        <!-- <el-table-column label="Status" width="false" fixed="right" align="center">
          <template slot-scope="scope">
            <statusDisplay :statusContent="scope.row.status" />
          </template>
        </el-table-column> -->

        <el-table-column label="操作" min-width="100" fixed="right" align="center">
          <template slot-scope="scope">
            <!-- <RoleButton
              :role-status="scope.row.status"
              @onClickButton="onClickButton($event, scope.row)"
            /> -->
          </template>
        </el-table-column>
      </PocTable>
    </el-row>
  </div>
</template>

<script>
import { refusedEntryColumn } from '@/views/intern/columnConfig/refusedEntryColumn'
import searchFormLine from '@/views/intern/components/searchFormLine'
import {
  downloadAttach,
  exportInternList,
  internList,
  recall
} from '@/api/intern'
import statusDisplay from '@/views/intern/components/statusDisplay'
import RoleButton from '@/views/intern/components/RoleButton'
import {
  forRecruitmentColumn,
  forRecruitmentColumn1, resignColumn
} from "@/views/IT_Data/columnConfig/forRecruitmentColumn";
import { getItem } from '@/utils/storage'
import { downLoadInternListByStatus, getInternList } from "@/api/itData";
import { downLoadFileByBlob } from "@/utils/util";
export default {
  name: 'haveLeft',
  mixins: [$PCommon.TableMixin],
  components: {
    searchFormLine,
    statusDisplay,
    RoleButton
  },
  data() {
    return {
      tableData: [],
      fullscreenLoading:false,
      loading: false,
      maxHeight: '',
      selectVal: [],
      columnConfig: forRecruitmentColumn,
      columnConfig1: resignColumn,
      searchVal: {}
    }
  },
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 380
    this.queryApi()
  },

  methods: {
    clickHfmCode(item) {
      console.log(item)
      this.$router.push({
        path: '/intern/internDetails',
        query: {
          id: item.id
        }
      })
    },
    exportInternListClick() {
      let ruleForm = this.searchVal
      let data = {
        englishName: '',
        status: '2',
        localName: '',
        ids: this.mapSelectValToIds(),
        companyName: '',
        lmName: '',
        title: '',
        taOwner: '',
        internalBrand: '',
        joinDate: [],
        leaveDate: [],
        tabType: '4',
        ...ruleForm,
        ...getItem('internSearchData')
      }
      this.fullscreenLoading = true
      downLoadInternListByStatus(data).then(res=>{
        this.fullscreenLoading = false
        if(res){
          downLoadFileByBlob(res)
        }
      })
    },
    mapSelectValToIds() {
      return this.selectVal.map((item) => item.id).toString(',')
    },
    isSelect() {
      if (this.selectVal.length) {
        return true
      } else {
        this.$message.warning('请选择')
        return false
      }
    },
    downloadAttachClick() {
      if (this.isSelect()) {
        const data = {
          ids: this.mapSelectValToIds()
        }
        downloadAttach(data)
      }
    },
    queryApi(val = {}) {
      if (val) {
        this.searchVal = val
      }
      this.loading = true
      const data = {
        englishName: '',
        status: '2',
        localName: '',
        companyName: '',
        lmName: '',
        title: '',
        taOwner: '',
        internalBrand: '',
        joinDate: [],
        leaveDate: [],
        tabType: '4',
        tbhStatus: this.tbhStatus,
        pageSize: this.pageConfig.pageSize,
        pageNum: this.pageConfig.pageNum,
        ...val,
        ...getItem('internSearchData')
      }
      getInternList(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data && res.data.list) {
            this.tableData = res.data.list
            this.pageConfig.total = res.data.total
          } else {
            this.tableData = []
            this.pageConfig.total = 0
          }
        }
      })
    },
    toWithdraw(row) {
      recall({ ids: row.id }).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('撤回成功')
        }
      })
    },
    copy(row) {},
    onClickButton(event, row) {
      const buttonObj = {
        copy: () => {
          this.copy(row)
        },
        toWithdraw: () => {
          this.toWithdraw(row)
        }
      }
      buttonObj[event]()
    },
    handleSelectionChange(val) {
      this.selectVal = val
    }
  }
}
</script>

<style lang="scss" scoped>
.haveLeft {
}
</style>

<template>
  <div class="searchFormLine">
    <PocSlider minheight="60px" maxHeight="350px" btntype="primary">
      <el-form size="mini" :inline="true" label-position="top" class="minWidthInput">
        <!-- <el-form-item label="Status">
          <el-select v-model="ruleForm.status" clearable placeholder="请选择">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="HFM Code">
          <el-select v-model="ruleForm.hfmCode" clearable filterable placeholder="请选择">
            <el-option v-for="item in HFMList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Level 1">
          <el-select v-model="ruleForm.level1" clearable filterable placeholder="请选择" @change="levelChange(1)"
            @clear="ruleForm.levelIds_1 = []">
            <el-option v-for="item in levelList1" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Level 2">
          <el-select v-model="ruleForm.level2" clearable filterable placeholder="请选择" @change="levelChange(2)"
            @clear="ruleForm.levelIds_2 = []">
            <el-option v-for="item in levelList2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Level 3">
          <el-select v-model="ruleForm.level3" clearable filterable placeholder="请选择" @change="levelChange(3)"
            @clear="ruleForm.levelIds_3 = []">
            <el-option v-for="item in levelList3" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Join Date">
          <el-date-picker v-model="ruleForm.joinDate" type="daterange" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期"
            end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" clearable @change="joinDateChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Last Working Date">
          <el-date-picker v-model="ruleForm.leaveDate" type="daterange" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期"
            end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" clearable @change="lastWorkingDateChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="English Name">
          <el-input v-model="ruleForm.englishName" />
        </el-form-item>
        <el-form-item label="Chinese Name">
          <el-input v-model="ruleForm.chineseName" />
        </el-form-item>
        <el-form-item label="Working Location">
          <el-select v-model="ruleForm.location" clearable filterable placeholder="请选择">
            <el-option v-for="item in locationList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </PocSlider>
  </div>
</template>

<script>
import pocSlider from '@/components/poc-slider/poc-slider'
import { removeItem } from "@/utils/storage";
import { getHfmCodeList } from "@/api/organization";
import { getLevel1List, getLevel2List } from '@/api/itData';
import { getLevel3 ,getWorkLocation } from "@/api/personnelRequisition";
export default {
  name: 'searchFormLine',
  props: {
    position: {
      type: String,
      required: true
    }
  },
  components: {
    pocSlider
  },
  watch: {
    position: {
      handler(val) {
        removeItem('internSearchData')
        let obj = {
          forRecruitment: [
            { label: '新建', value: 1 },
            { label: '待招聘', value: 2 },
            { label: '已签回', value: 3 }
          ],
          onTheJob: [
            { label: '已入职', value: 4 },
            { label: '离职申请中', value: 5 },
            { label: '离职待审批', value: 6 },
            { label: '待离职', value: 7 }
          ],
          stayPositive: [],
          haveLeft: [{ label: '已离职', value: 8 }],
          refusedEntry: [{ label: '拒绝入职', value: 9 }]
        }
        if (val) {
          this.statusList = obj[val]
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      ruleForm: {
        englishName: '',
        hfmCode: '',
        chineseName: '',
        level1: '',
        level2: '',
        level3: '',
        joinDate: null,
        joinDateStart: '',
        joinDateEnd: '',
        lastWorkingDateStart: '',
        lastWorkingDateEnd: '',
        location: ''
      },
      HFMList: [],
      statusList: [
        // { label: '新建', value: 1 },
        // { label: '待招聘', value: 2 },
        // { label: '已签回', value: 3 },
        // { label: '已入职', value: 4 },
        // { label: '离职申请中', value: 5 },
        // { label: '离职待审批', value: 6 },
        // { label: '待离职', value: 7 },
        // { label: '已离职', value: 8 },
        // { label: '拒绝入职', value: 9 }
      ],
      visible: false,
      levelList1: [],
      levelList2: [],
      levelList3: [],
      locationList: []
    }
  },
  created() {
    removeItem('internSearchData')
    this.init()


  },
  methods: {
    init() {
      this.getWorkLocation()
      this.getHfmCodeList()
      this.getLevelList1()
      this.getLevelList2()
      this.getLevelList3()
    },
    getHfmCodeList() {
      getHfmCodeList().then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.HFMList = res.data
          }
        }
      })
    },
    getLevelList1() {
      getLevel1List().then(({code, data}) => {
        if (code == 200) {
          this.levelList1 = data;
        }
      })
    },
    getLevelList2() {
      let level1Id = this.ruleForm.level1 ? this.ruleForm.level1 : ''
      getLevel2List(level1Id).then(({code, data}) => {
        if (code == 200) {
          this.levelList2 = data;
        }
      })
    },
    getLevelList3() {
      let parmas = {
        level2Id: this.ruleForm.level2
      }
      getLevel3(parmas).then(({code, data}) => {
        if (code == 200) {
          this.levelList3 = data;
        }
      })
    },
    levelChange(val) {
      if (val == 1) {
        this.ruleForm.level2 = '';
        this.ruleForm.level3 = '';
        this.getLevelList2();
        this.getLevelList3();
      } else if (val == 2) {
        this.ruleForm.level3 = '';
        this.getLevelList3()
      } else {

      }
    },
    getWorkLocation() {
      getWorkLocation().then(({code, data}) => {
        if (code == 200) {
          this.locationList = data;
        }
      })
    },
    unfold() {
      this.visible = true
    },
    joinDateChange(val) {
      if (val) {
        this.ruleForm.joinDateStart = val[0]
        this.ruleForm.joinDateEnd = val[1]
      } else {
        this.ruleForm.joinDateStart = ''
        this.ruleForm.joinDateEnd = ''
      }
    },
    lastWorkingDateChange(val) {
      if (val) {
        this.ruleForm.lastWorkingDateStart = val[0]
        this.ruleForm.lastWorkingDateEnd = val[1]
      } else {
        this.ruleForm.lastWorkingDateStart = ''
        this.ruleForm.lastWorkingDateEnd = ''
      }
    },
    close() {
      this.visible = false
      // this.reset()
    },
    reset() {
      Object.keys(this.ruleForm).forEach((item) => {
        if (item === 'joinDate' || item === 'leaveDate') {
          this.ruleForm[item] = []
        } else {
          this.ruleForm[item] = ''
        }
      })
    },
    search() {
      // delete this.ruleForm.leaveDate
      // delete this.ruleForm.joinDate
      this.$emit('onSearch', JSON.parse(JSON.stringify(this.ruleForm)))
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.searchFormLine {}
</style>
